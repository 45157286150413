export  const conditions = [
  "Choose a quiet and comfortable setting for taking the test where interruptions are unlikely. Make sure that your environment is devoid of any distractions to help keep your focus during the entire test.",
  
    "Ensure that your computer and internet connection are functioning correctly. Access the test using a reliable and updated web browser.",
  
    "Once initiated, the timed test cannot be paused. Be ready to go through the entire session without taking breaks.",
  
    "Read each question thoroughly along with all provided answer choices before making your selection. Remember, there are no penalties for wrong answers.",
  
  "Manage your time wisely to ensure that you can attempt each question within the allotted time.",
];
